import { CittaGameLogo, Facebook, Instagram, Telegram, Twitter, Youtube } from 'assets/icons/icon';
import memberShipLogo from 'assets/images/membership.png';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FREE_PRIVACY_POLICY } from 'shared/constants/constants';

const Footer: React.FC = () => {
	const location = useLocation();

	const year = new Date().getFullYear();

	return (
		<div className="footer-section">
			{location.pathname !== FREE_PRIVACY_POLICY && (
				<div className="footer-top-wrapper  d-flex flex-direction--row justify-content-space-between ">
					<div className="d-flex social-icon-membership-wrapper">
						<div className="social-icon-wrapper">
							<CittaGameLogo height="48" width="166" />
							<div className="social-icon mt-60 d-flex justify-content-space-between">
								<a href="https://www.facebook.com/cittagamesofficial" target="_blank">
									<Facebook className="social-media--icon" color="#f9f9f9" />
								</a>
								<a href="https://www.instagram.com/cittagamesofficial/" target="_blank">
									<Instagram className="social-media--icon" color="#f9f9f9" />
								</a>
								<a href="https://www.youtube.com/@cittagamesofficial" target="_blank">
									<Youtube className="social-media--icon" color="#f9f9f9" />
								</a>
								<a href="https://t.me/citta_games" target="_blank">
									<Telegram className="social-media--icon" color="#f9f9f9" />
								</a>
								<a href="https://twitter.com/cittagames" target="_blank">
									<Twitter className="social-media--icon" color="#f9f9f9" />
								</a>
							</div>
						</div>

						<div className="membership-wrapper-wrapper">
							<p className="no-margin mb-10 font-size-18 font-weight--600 line-height-21 text-align--center">Member of</p>
							<div className="membership-logo">
								<img src={memberShipLogo} alt="banner img" />
							</div>
						</div>
					</div>

					<div className="d-flex flex-direction--row policy-wrapper justify-content-space-between">
						<div className="footer-link-wrapper">
							<p className="font-size-18 font-weight--600  line-height-21">Games</p>
							<ul>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="/8ball-pool" className="text--decoration-none grey-color hover">
										8 Ball Pool
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="tic-tac-toe" className="text--decoration-none grey-color hover">
										Tic Tac Toe
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="ludo-race" className="text--decoration-none grey-color hover">
										Ludo Race
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="cashy-carrom" className="text--decoration-none grey-color hover">
										Cashy Carrom
									</Link>
								</li>
							</ul>
						</div>
						<div className="footer-link-wrapper">
							<p className="font-size-18 font-weight--600  line-height-21">Info</p>
							<ul>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="about" className="text--decoration-none grey-color hover">
										About
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="faqs" className="text--decoration-none grey-color hover">
										FAQs
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="how-to-play" className="text--decoration-none grey-color hover">
										How to Play
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="contact-us" className="text--decoration-none grey-color hover">
										Contact Us
									</Link>
								</li>
							</ul>
						</div>
						<div className="footer-link-wrapper">
							<p className="font-size-18 font-weight--600  line-height-21">Legal</p>
							<ul>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to={'refund-cancellation'} className="text--decoration-none grey-color hover">
										Refund & Cancellation
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18 ">
									<Link to="privacy-policy" className="text--decoration-none grey-color hover">
										Privacy Policy
									</Link>
								</li>
								<li className="mb-18 font-size-16 font-weight--400 grey-color line-height-18">
									<Link to="terms-conditions" className="text--decoration-none grey-color hover">
										Terms & Conditions
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			<div className="footer-line" />

			<div className="footer-bottom-wrapper">
				<div className="text-align--center">
					<h6 className="font-size-16 font-weight--400 line-height-18 mb-18">Branch Office</h6>
					<p className="address font-size-16 font-weight--400 line-height-18 grey-color">
						A-506, Shapath Hexa, Opp. Kargil Petrol Pump, Near Sola Bridge, Sola, Ahmedabad, Gujarat, India - 380060
					</p>
				</div>
				<div className="d-flex company-info justify-content-space-between align-items-center mt-40 pb-30">
					<div>
						<p className="font-size-16 font-weight--400 line-height-18 grey-color copyright-text">Copyright © {year} Cittagames.</p>
					</div>
					<div className="d-flex flex-direction--row company-name d-flex align-items-center">
						<p className="font-size-16 font-weight--400 line-height-18 grey-color">Sharpenminds Technologies Pvt. Ltd.</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Footer;
