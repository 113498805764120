import { lazy } from 'react';

export const Home = lazy(() => import('features/home/home'));
export const About = lazy(() => import('features/about/about'));
export const HowToPlay = lazy(() => import('features/howToPlay/howToPlay'));
export const ReferEarn = lazy(() => import('features/refer&Earn/referEarn'));
export const PrivacyPolicy = lazy(() => import('features/privacyPolicy/privacyPolicy'));
export const FreePrivacyPolicy = lazy(() => import('features/freePrivacyPolicy/freePrivacyPolicy'));
export const TermsAndConditions = lazy(() => import('features/termsAndCondition/termsAndConditions'));
export const RefundAndCancellation = lazy(() => import('features/refundAndCancellation/refundAndCancellation'));
export const ContactUs = lazy(() => import('features/contactUs/contactUs'));
export const LudoRace = lazy(() => import('features/ludoRace/ludoRace'));
export const EightBallPool = lazy(() => import('features/eightBallPool/eightBallPool'));
export const TicTacToe = lazy(() => import('features/ticTacToe/ticTacToe'));
export const CashyCarrom = lazy(() => import('features/cashyCarrom/cashyCarrom'));
export const Faqs = lazy(() => import('shared/components/faqs/faqs'));
