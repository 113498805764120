import React from 'react';
import './spinner.scss';

const Spinner: React.FC = () => {
	return (
		<div className="loader">
			<div className="spinner" />
		</div>
	);
};

export default Spinner;
