import 'assets/styles/main.scss';
import { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import Layout from 'shared/components/layout/layout';
import {
	About,
	CashyCarrom,
	ContactUs,
	EightBallPool,
	Faqs,
	FreePrivacyPolicy,
	Home,
	HowToPlay,
	LudoRace,
	PrivacyPolicy,
	ReferEarn,
	RefundAndCancellation,
	TermsAndConditions,
	TicTacToe
} from 'shared/components/layout/asyncComponents';
import Spinner from 'shared/components/spinner/spinner';

const App: React.FC = () => {
	return (
		<Router>
			<Layout>
				<Suspense fallback={<Spinner />}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/how-to-play" element={<HowToPlay />} />
						<Route path="/refer&earn" element={<ReferEarn />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/free/privacy-policy" element={<FreePrivacyPolicy />} />
						<Route path="/terms-conditions" element={<TermsAndConditions />} />
						<Route path="/contact-us" element={<ContactUs />} />
						<Route path="/refund-cancellation" element={<RefundAndCancellation />} />
						<Route path="/ludo-race" element={<LudoRace />} />
						<Route path="/8ball-pool" element={<EightBallPool />} />
						<Route path="/tic-tac-toe" element={<TicTacToe />} />
						<Route path="/cashy-carrom" element={<CashyCarrom />} />
						<Route path="/faqs" element={<Faqs />} />
						<Route path="*" element={<Navigate replace to="/" />} />
					</Routes>
				</Suspense>
			</Layout>
		</Router>
	);
};

export default App;
