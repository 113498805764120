import { CittaGameLogo, CloseIcon, Menu } from 'assets/icons/icon';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { NavLink, useLocation } from 'react-router-dom';
import DownloadButton from 'shared/components/downloadButton/downloadButton';
import { FREE_PRIVACY_POLICY } from 'shared/constants/constants';

const Header: React.FC = () => {
	const [open, setOpen] = useState(false);
	const location = useLocation();

	return (
		<div className="header-section d-flex  align-items-center justify-content-space-between font-style-italic">
			<NavLink to="/" className={'logo-wrapper'}>
				<CittaGameLogo className="logo cursor-pointer" />
			</NavLink>
			{location.pathname !== FREE_PRIVACY_POLICY && (
				<>
					<div className="d-flex header-items align-items-center">
						<div className="page-tabs mr-80">
							<ul className="d-flex">
								<NavLink
									to="/"
									className="text--decoration-none white-color font-size-14 line-height-18 navbar-hover hover letter-spacing-03  font-weight--600 "
								>
									<li>Home</li>
								</NavLink>
								<NavLink
									to="/refer&earn"
									className="text--decoration-none white-color font-size-14  line-height-18 hover navbar-hover letter-spacing-03  font-weight--600"
								>
									<li>Refer & Earn</li>
								</NavLink>
								<NavLink
									to="/about"
									className="text--decoration-none white-color font-size-14  line-height-18 hover navbar-hover letter-spacing-03 font-weight--600"
								>
									<li>About</li>
								</NavLink>
							</ul>
						</div>
						<DownloadButton width="120" height="40" isHeaderSection className="header-download-new-btn" />
					</div>

					<div onClick={() => setOpen(true)} className="hamburger-icon d-none">
						<Menu className="menu " />
					</div>
				</>
			)}
			{open &&
				createPortal(
					<div className="sidenav-mobile-wrapper">
						<div className="sidenav">
							<div className="close-icon" onClick={() => setOpen(false)}>
								<CloseIcon />
							</div>
							<ul className="flex flex-direction--column width--full">
								<li className="sidenav-item" onClick={() => setOpen(false)}>
									<NavLink to="/" className="hover white-color">
										Home
									</NavLink>
								</li>
								<li className="sidenav-item" onClick={() => setOpen(false)}>
									<NavLink to="/refer&earn" className="hover white-color">
										Refer & Earn
									</NavLink>
								</li>
								<li className="sidenav-item" onClick={() => setOpen(false)}>
									<NavLink to="/about" className="hover white-color">
										About
									</NavLink>
								</li>
							</ul>
						</div>
						<div className="overlay" onClick={() => setOpen(false)} />
					</div>,
					document.body
				)}
		</div>
	);
};

export default Header;
