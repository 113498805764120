import { DOWNLOAD_IOS_URL, DOWNLOAD_URL, isOS } from './constants/constants';

export const handleDownload = (isIOSLink = isOS) => {
	const link = document.createElement('a');
	link.target = '_blank';
	if (isIOSLink) {
		link.href = DOWNLOAD_IOS_URL;
	} else {
		link.href = DOWNLOAD_URL;
		link.type = 'application/vnd.android.package-archive';
	}
	document.body.appendChild(link);
	link.click();

	// window.gtag('event', 'send', { event_category: 'Download', event_action: 'Click', event_label: 'Download app', event_value: '0' });
	window.dataLayer.push({ event_category: 'Download', event_action: 'Click', event_label: isIOSLink ? 'iOS app' : 'Android App', event_value: '0' });
};
